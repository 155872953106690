import React, { useContext, useState } from 'react';
import moment from 'moment';
import { FormControl } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { NotificationContext } from '../../../NotificationContext';
import configs from './configs';
import LocalizedDatePicker from '../../../custom-widgets/LocalizedDatePicker';

const EditDateTimeCell = (props) => {
    const { id, field, value: cellValue, row, table } = props;
    const apiRef = useGridApiContext();
    const { showSnackbar } = useContext(NotificationContext);

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();
    const [textFieldValue, setTextFieldValue] = useState();

    const handleMutate = async (value) => {
        try {
            const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
            const formattedRow = {
                ...row,
                [field]: value.toISOString(),
            };

            extractIdFields.forEach((_field) => {
                if (formattedRow[_field] && formattedRow[_field]._id) {
                    formattedRow[_field] = formattedRow[_field]._id;
                }
            });

            const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });

            apiRef.current.setEditCellValue({
                id,
                field,
                value,
            });

            return mutateConfig.onMutate({ id, body }, { mutateAsync });
        } catch (error) {
            showSnackbar({ severity: 'error', message: error.message });
        }
    };

    const handleChange = (event) => {
        setTextFieldValue(event);
    };

    const triggerMutate = () => {
        handleMutate(textFieldValue);
    };

    return (
        <FormControl
            fullWidth
            variant="outlined"
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
            }}
        >
            <LocalizedDatePicker
                value={moment(cellValue)}
                onAccept={handleMutate}
                onChange={handleChange}
                slotProps={{
                    textField: {
                        onKeyDown: ({ key }) => {
                            if (key === 'Enter') triggerMutate();
                        },
                    },
                }}
                // wrap
            />
        </FormControl>
    );
};

export default EditDateTimeCell;
