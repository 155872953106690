import React, { useState } from 'react';
import moment from 'moment-timezone';
import store from 'app/store';
import Papa from 'papaparse';
import _ from '@lodash';
import listOfStates from 'us-state-converter';
import FMMO from 'app/constants/pooling';
import { uploadFile, uploadImage } from './app/repository';
import path from 'path';
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { parsePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'libphonenumber-js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { TCJ_REGIONS } from './app/constants/bulkRegion';
import Region from './app/constants/region';
import ENVIRONMENTS from './app/constants/enviroments';
import { districts } from './app/custom-components/form-components/ReactHookProducerFormSelects';

/* eslint-disable */
export const LAB_STANDARDS = {
    NL: {
        SCC_WARNING: 300,
        SCC_ERROR: 400,
        BACTO_WARNING: 75,
        BACTO_ERROR: 121,
        BACTO_PLC_WARNING: 30000,
        BACTO_PLC_ERROR: 50000,
    },
    PEI: {
        SCC_WARNING: 350,
        SCC_ERROR: 400,
        SCC_VIOLATION: 400,
        BACTO_WARNING: 80,
        BACTO_ERROR: 121,
        BACTO_VIOLATION: 121,
        FREEZING_POINT_WARNING: -0.53,
        FREEZING_POINT_ERROR: -0.525,
        FREEZING_POINT_VIOLATION: -0.525,
    },
    RF: {
        BMCC_WARNING: 250,
        BMCC_ERROR: 400,
        BACTO_WARNING: 80,
        BACTO_ERROR: 200,
        BACTO_VIOLATION: 600,
        THERMO_PC_WARNING: 1500,
        THERMO_PC_ERROR: 3000,
        THERMO_PC_VIOLATION: 6000,
        TEMPERATURE_ERROR: 5,
        COLOSTRUM_ERROR: 0.3,
    },
    CDI: {
        CC_WARNING: 100,
        CC_ERROR: 750,
        SCC_WARNING: 225,
        SCC_ERROR: 400,
        LPC_WARNING: 100,
        LPC_ERROR: 750,
        SPC_WARNING: 15,
        SPC_ERROR: 50,
    },
    BORDEN: {
        SCC_WARNING: 300,
        SCC_ERROR: 400,
        BACTO_WARNING: 75,
        BACTO_ERROR: 121,
        BACTO_PLC_WARNING: 30000,
        BACTO_PLC_ERROR: 50000,
    },
    UDA: {
        SCC_BONUS: 225,
        SCC_WARNING: 225,
        SCC_ERROR: 400,
        SPC_WARNING: 300,
        PI_WARNING: 30000,
        LPC_WARNING: 200,
        FREEZING_POINT_WARNING: 0.529,
        TEMPERATURE_ERROR: 38,
        TEMPERATURE_REJECT: 47,
        TEMPERATURE_BONUS: 37,
        ACIDITY_WARNING: 0.17,
        ACIDITY_REJECT: 0.19,
        AFLATOXIN_WARNING: 0.2,
        AFLATOXIN_REJECT: 0.51,
    },
    PRAIRIE: {},
    LEGACY: {},
    MMPA: {},
};
/* eslint-enable */

let API_HOST = '';
let EXPRESS_API_HOST = '';
let EQUITY_MASTER_VALUES_API_HOST = '';
let EQUITY_MEMBERSHIP_API_HOST = '';
let LABS_API_HOST = '';
let REPORT_SERVICE_API_HOST = '';

/**
 * Get ID of current user
 * If subuser, returns ID of parent account
 * @returns {string} Object ID of current user
 */
export function getId() {
    const userData = store.getState().persisted.auth.user.data;

    if (!userData.subuser) return userData.id;

    switch (userData.role) {
        case 'producer':
            return userData.producer_id;
        case 'processor':
            return userData.processor_id;
        case 'transport':
            return userData.hauling_id;
        case 'admin':
            return userData.admin_id;
        default:
            return userData.id;
    }
}

/**
 * Get auth role of current user
 * @returns {string} Role of current user
 */
export const getRole = () => store.getState().persisted.auth.user.data.role;

export function setAPIHosts(url) {
    if (url.includes('localhost')) {
        // allows us to use local builds the same
        API_HOST = 'http://localhost:3000';
        EXPRESS_API_HOST = 'http://localhost:3001';
        return;
    }

    const ecsRegionName = url.split('.')[0]; // value before the first period but after https
    let ecsDeployLevel = url.split('.')[1]; // value after the first period, but before the second

    if (ecsDeployLevel === 'milkmoovement') {
        ecsDeployLevel = 'prod'; // replace with prod if not set
    }

    API_HOST = `https://${ecsRegionName}-api-loopback.${ecsDeployLevel}.milkmoovement.io`;
    EXPRESS_API_HOST = `https://${ecsRegionName}-api-express.${ecsDeployLevel}.milkmoovement.io`;
}

export const isDemoServer = () => {
    if (window.location.href.includes('demo.')) {
        return true;
    }
    return false;
};

export function setServerlessAPIHosts(url) {
    if (url.includes('localhost')) {
        LABS_API_HOST = 'https://lab.milkmoovement.services';
        EQUITY_MASTER_VALUES_API_HOST = 'https://local-equity-master-value.milkmoovement.services';
        EQUITY_MEMBERSHIP_API_HOST = 'https://local-equity-member.milkmoovement.services';
        REPORT_SERVICE_API_HOST = 'https://local-report.milkmoovement.services';
        return;
    }

    let ecsDeployLevel = url.split('.')[1]; // value after the first period, but before the second
    if (ecsDeployLevel === 'milkmoovement') {
        ecsDeployLevel = 'prod'; // replace with prod if not set
    }

    LABS_API_HOST = `https://lab.${ecsDeployLevel}.milkmoovement.io`;
    EQUITY_MASTER_VALUES_API_HOST = `https://${ecsDeployLevel}-equity-master-value.${ecsDeployLevel}.milkmoovement.io`;
    EQUITY_MEMBERSHIP_API_HOST = `https://${ecsDeployLevel}-equity-member.${ecsDeployLevel}.milkmoovement.io`;
    REPORT_SERVICE_API_HOST = `https://${ecsDeployLevel}-report.${ecsDeployLevel}.milkmoovement.io`;
}

export function getLabsAPIHost() {
    return LABS_API_HOST;
}

export function getEquityMasterValuesAPIHost() {
    return EQUITY_MASTER_VALUES_API_HOST;
}

export function getEquityMembershipAPIHost() {
    return EQUITY_MEMBERSHIP_API_HOST;
}

export function getReportServiceAPIHost() {
    return REPORT_SERVICE_API_HOST;
}

export function getAPIHost() {
    return API_HOST;
}

export function getExpressAPIHost() {
    return EXPRESS_API_HOST;
}

export function setAPIHostnames(url) {
    const ecsHostName = new URL(url).host;
    setAPIHosts(ecsHostName);
    setServerlessAPIHosts(url);
}

export const getDateSelectorStartTime = (region) =>
    ({
        NL: '2016-09',
        PEI: '2019-04',
        RF: '2015-07',
        CDI: '2019-01',
        BORDEN: '2021-06',
        UDA: '2016-01',
        PRAIRIE: '2016-10',
        LEGACY: '2018-02',
        MMPA: '2020-01',
        BONGARDS: '2010-01',
    }[region]);

export const getTimeZone = (region) =>
    ({
        NL: 'America/St_Johns',
        PEI: 'America/Halifax',
        RF: 'Australia/Sydney',
        CDI: 'America/Los_Angeles',
        BORDEN: 'America/Chicago',
        UDA: 'America/Phoenix',
        PRAIRIE: 'America/Chicago',
        LEGACY: 'America/Los_Angeles',
        MMPA: 'America/New_York',
        'TCJ-LIBERTY': 'America/New_York',
        'TCJ-WHITEEAGLE': 'America/New_York',
        'TCJ-GPDA': 'America/Chicago',
        'TCJ-ERIE': 'America/New_York',
        'TCJ-NEBRASKA': 'America/Chicago',
        'TCJ-KYTN': 'America/Chicago',
        DFO: 'America/New_York',
        DEMO: 'America/New_York',
        UNC: 'America/New_York',
        BONGARDS: 'America/Chicago',
        PSC: 'America/Chicago',
    }[region]);

export function getDistanceBasedOnRegion(region, meters) {
    if (parseFloat(meters) > 0) {
        if (['CDI', 'BORDEN', 'UDA', 'PRAIRIE', 'LEGACY', 'MMPA'].includes(region)) {
            return parseFloat(meters) * 0.00062137;
        }
        return meters / 1000;
    }
    return 0;
}

export function numberFormat(number, decimals, decPoints, thousandsSep) {
    // As found at https://stackoverflow.com/questions/12820312/equivalent-to-php-function-number-format-in-jquery-javascript, by Umair Hamid
    const num = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
    // eslint-disable-next-line no-restricted-globals
    const n = !isFinite(+num) ? 0 : +num;
    // eslint-disable-next-line no-restricted-globals
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    const dec = typeof decPoints === 'undefined' ? '.' : decPoints;

    const toFixedFix = (N, Prec) => {
        const k = 10 ** Prec;
        return `${Math.round(N * k) / k}`;
    };

    const s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/**
 * Converts a duration in seconds to a human readable string in format hh:mm:ss
 *
 * @param {number} duration duration in seconds to convert to human readable time
 * @returns {string} string in format hh:mm:ss
 */
export function secondsToTimeConverter(duration) {
    let duration_typesafe = 0;
    if (!Number.isNaN(duration)) duration_typesafe = parseFloat(duration);
    // eslint-disable-next-line no-console
    else console.warn(`function secondsToTimeConverter expects a parameter of type number, instead recieved type ${typeof duration}`);

    let seconds = duration_typesafe.toFixed(0);

    const days = Math.floor(seconds / (3600 * 24 * 1000));
    seconds -= days * 3600 * 24 * 1000;
    let hrs = Math.floor(seconds / (3600 * 1000)) + days * 24;
    seconds -= (hrs - days * 24) * 3600 * 1000;
    let mnts = Math.floor(seconds / (60 * 1000));
    seconds -= mnts * 60 * 1000;
    seconds = Math.floor(seconds / 1000);

    if (hrs < 10) {
        hrs = `0${hrs}`;
    }
    if (mnts < 10) {
        mnts = `0${mnts}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    return `${hrs}:${mnts}:${seconds}`;
}

export function getPrairieTestType(value) {
    if (value.test_type) {
        return value.test_type?.toUpperCase();
    }
    if (value.bacto_scan && value.standard_plate_count && value.preliminary_incubation) {
        if (value.antibiotics && value.sediments) {
            return 'Monthly';
        }
        return 'Weekly';
    }
    return 'Daily';
}

export function metersToMilesConverter(meters) {
    if (parseFloat(meters) > 0) {
        return parseFloat(meters) * 0.00062137;
    }
    return 0;
}

export function milesToMeters(miles) {
    const value = parseFloat(miles);
    return value > 0 ? value * 1609.344 : 0;
}

export function roundMetersToMiles(meters) {
    const value = parseFloat(meters);
    const miles = value > 0 ? value / 1609.344 : 0;
    return Math.round(miles);
}
export function downloadCSV(data = null, title = 'milkmoovement.csv') {
    if (!data) return null;

    const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    // IE11 & Edge
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, title);
    } else {
        // In FF link must be added to DOM to be clicked
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute('download', title);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function getBulkChartTemplate() {
    const rows = [['CM', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']];

    for (let x = 0; x < 100; x++) {
        const head = x < 10 ? `0${x.toString()}` : x.toString();

        rows.push([head, '', '', '', '', '', '', '', '', '', '', '']);
    }

    return rows;
}

export function parseBulkChart(chart) {
    const rows = [['CM']];

    for (let x = 0; x < chart[Object.keys(chart)[0]].length; x++) {
        rows[0].push((x + 1).toString());
    }

    for (let y = 0; y < 100; y++) {
        let strInt = y.toString();
        if (y < 10) {
            strInt = `0${strInt}`;
        }

        if (strInt in chart) {
            chart[strInt].unshift(strInt);
            rows.push(chart[strInt]);
        }
    }

    return rows;
}

export function getMonthDateRange(year, month, defaultMonthToDate = false) {
    const startDate = moment([year, month - 1])
        .startOf('month')
        .format();

    const isCurrentMonth = moment(startDate).format('YYYY-MM') === moment().format('YYYY-MM');
    const endDate = isCurrentMonth && defaultMonthToDate ? moment().format() : moment(startDate).endOf('month').format();

    return {
        start: startDate,
        end: endDate,
    };
}

export function bootInitialCustomIntercomLauncher() {
    const launcher = document.querySelector('.intercom-launcher');
    const unreadCount = launcher.querySelector('.intercom-unread-count');

    window.Intercom('boot', {
        app_id: 'mf8emzsc',
        custom_launcher_selector: '.intercom-launcher',
        hide_default_launcher: true,
        vertical_padding: 100,
    });

    window.Intercom('onShow', () => launcher.classList.add('intercom-open'));
    window.Intercom('onHide', () => launcher.classList.remove('intercom-open'));

    window.Intercom('onUnreadCountChange', (count) => {
        unreadCount.textContent = count;
        if (count) {
            unreadCount.classList.add('active');
        } else {
            unreadCount.classList.remove('active');
        }
    });

    // eslint-disable-next-line no-use-before-define
    const timeout = setTimeout(() => clearInterval(interval), 30000);
    const interval = setInterval(() => {
        if (window.Intercom.booted) {
            launcher.classList.add('intercom-booted');
            clearInterval(interval);
            clearTimeout(timeout);
        }
    }, 100);
}

export function getLabBoxComponentTextColor(component, value, region) {
    const normal = 'text-green';
    const warning = 'text-orange';
    const error = 'text-red';
    switch (component) {
        case 'scc':
            if (value < LAB_STANDARDS[region].SCC_WARNING) return normal;
            if (value < LAB_STANDARDS[region].SCC_ERROR) return warning;
            return error;
        case 'bmcc':
            if (value < LAB_STANDARDS[region].BMCC_WARNING) return normal;
            if (value < LAB_STANDARDS[region].BMCC_ERROR) return error;
            return error;
        case 'bacto':
            if (value < LAB_STANDARDS[region].BACTO_WARNING) return normal;
            if (value < LAB_STANDARDS[region].BACTO_ERROR) return region === 'RF' ? error : warning;
            return error;
        case 'thermo':
            if (value < LAB_STANDARDS[region].THERMO_PC_WARNING) return normal;
            if (value < LAB_STANDARDS[region].THERMO_PC_ERROR) return error;
            return error;
        case 'temp':
            if (value < LAB_STANDARDS[region].TEMPERATURE_ERROR) return normal;
            if (value > LAB_STANDARDS[region].TEMPERATURE_ERROR) return error;
            return error;
        default:
            return normal;
    }
}

export function getUDALabTextColor(component, value) {
    const normal = 'text-black';
    const premium = 'text-green-dark';
    const warning = 'text-orange-dark';
    const error = 'text-red';
    switch (component) {
        case 'scc':
            if (value < LAB_STANDARDS.UDA.SCC_WARNING) return premium;
            if (value < LAB_STANDARDS.UDA.SCC_ERROR) return warning;
            return error;
        case 'spc':
            if (value < LAB_STANDARDS.UDA.SPC_WARNING) return premium;
            return normal;
        case 'pi':
            if (value < LAB_STANDARDS.UDA.PI_WARNING) return premium;
            return normal;
        case 'lpc':
            if (value < LAB_STANDARDS.UDA.LPC_WARNING) return premium;
            return normal;
        case 'ppb':
            if (value > LAB_STANDARDS.UDA.PPB_WARNING) return error;
            return normal;
        case 'freezing_point':
            if (value > LAB_STANDARDS.UDA.FREEZING_POINT_WARNING) return error;
            return normal;
        case 'temperature':
            if (value < LAB_STANDARDS.UDA.TEMPERATURE_ERROR) return premium;
            if (value > LAB_STANDARDS.UDA.TEMPERATURE_ERROR) return error;
            return normal;
        case 'acidity':
            if (value > LAB_STANDARDS.UDA.ACIDITY_WARNING) return error;
            return normal;
        default:
            return normal;
    }
}

export function getCDILabTextColor(component, value) {
    const normal = 'text-black';
    const warning = 'text-green';
    const error = 'text-red';
    switch (component) {
        case 'cc':
            if (value < LAB_STANDARDS.CDI.CC_WARNING) return warning;
            if (value > LAB_STANDARDS.CDI.CC_ERROR) return error;
            return normal;
        case 'scc':
            if (value < LAB_STANDARDS.CDI.SCC_WARNING) return warning;
            if (value > LAB_STANDARDS.CDI.SCC_ERROR) return error;
            return normal;
        case 'spc':
            if (value < LAB_STANDARDS.CDI.SPC_WARNING) return warning;
            if (value > LAB_STANDARDS.CDI.SPC_ERROR) return error;
            return normal;

        case 'lpc':
            if (value < LAB_STANDARDS.CDI.LPC_WARNING) return warning;
            if (value > LAB_STANDARDS.CDI.LPC_ERROR) return error;
            return normal;

        default:
            return normal;
    }
}

export function getLegacyLabTextColor(component, value) {
    const normal = 'text-black';
    const error = 'text-red';
    switch (component) {
        case 'snf':
            if (value < 8.3 || value > 10) return error;
            return normal;
        case 'fat':
            if (value < 3.1 || value > 5.3) return error;
            return normal;
        case 'protein':
            if (value < 2.8 || value > 3.9) return error;
            return normal;
        case 'temperature':
            if (value > 45) return error;
            return normal;
        default:
            return normal;
    }
}

export function getPenaltyStatusImage(reg, status, type) {
    if (reg === 'NL' || (reg === 'PEI' && type === 'temperature')) {
        return status === 0 ? 'assets/MMAssets/icons/SAFE.jpg' : status === 1 ? 'assets/MMAssets/icons/WARNING.jpg' : 'assets/MMAssets/icons/PENALTY.jpg';
    }

    switch (status) {
        case 1:
            return 'assets/MMAssets/icons/INFRACTION.jpg';
        case 2:
            return 'assets/MMAssets/icons/WARNING.jpg';
        case 3:
            return 'assets/MMAssets/icons/PENALTY.jpg';
        default:
            return 'assets/MMAssets/icons/SAFE.jpg';
    }
}

export function getStandardDeviation(array) {
    const avg = _.mean(array);
    return Math.sqrt(_.sum(_.map(array, (i) => (i - avg) ** 2)) / array.length);
}

export function getDefaultLatitude(region) {
    switch (region) {
        case 'NL':
            return 49.578749;
        case 'PEI':
            return 46.47011456406842;
        case 'SM':
            return 43.865295757797604;
        case 'RF':
            return -28.58880663161527;
        case 'CDI':
            return 37.64677287164822;
        case 'UDA':
            return 33.7625104;
        case 'BORDEN':
            return 38.8293714;
        case 'PRAIRIE':
            return 44.29468864439687;
        case 'LEGACY':
            return 37.5112927;
        case 'MMPA':
            return 43.0097854;
        default:
            return 49.578749;
    }
}

export function getDefaultLongitude(region) {
    switch (region) {
        case 'NL':
            return -56.00428;
        case 'PEI':
            return -63.37610617187499;
        case 'SM':
            return -89.89412853125;
        case 'RF':
            return 134.4483619744792;
        case 'CDI':
            return -119.86449275675979;
        case 'UDA':
            return -113.2574432;
        case 'BORDEN':
            return -94.3142473;
        case 'PRAIRIE':
            return -93.26371347142637;
        case 'LEGACY':
            return -120.7571164;
        case 'MMPA':
            return -84.3585764;
        default:
            return -56.00428;
    }
}

export function getGoogleMapsURL() {
    return `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`;
}

export function getDefaultZoom(region) {
    switch (region) {
        case 'PEI':
            return 9.5;
        case 'RF':
            return 5;
        case 'CDI':
        case 'BORDEN':
            return 6;
        case 'LEGACY':
        case 'MMPA':
            return 9;

        default:
            return 7;
    }
}

export function getDaysInMonth(m, y) {
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    return m === 2 ? (y & 3 || (!(y % 25) && y & 15) ? 28 : 29) : 30 + ((m + (m >> 3)) & 1);
}

export function getTwoStringOfMonth(m) {
    return moment({ month: (parseInt(m) - 1) % 12 }).format('MM');
}

export function combinePickups(pickups) {
    return _.reduce(
        _.cloneDeep(pickups),
        (acc, val) => {
            const pickup = _.find(acc, {
                created_at: val.created_at,
                producer_id: val.producer_id,
                tank_number: val.tank_number,
            });
            if (pickup) {
                pickup.volume += val.volume;
                pickup.volume_a += val.volume_a;
                pickup.volume_b += val.volume_b;
                pickup.volume_c += val.volume_c;
            } else {
                acc.push(val);
            }
            return acc;
        },
        []
    );
}

export function volumeDisplay(s) {
    return `${s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function poundDisplay(s) {
    return `${s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function getStatsStartDate() {
    return moment().startOf('month');
}

export function getStatsEndDate() {
    return moment().endOf('month');
}

export const dataTableSort = (a, b, property) => (a[property] === null || a[property] < b[property] || a[property] === undefined ? -1 : 1);

export const dateColumnCompare = (a, b, property, isMoment = true) => {
    if (!a[property] || (isMoment && !a[property].isValid())) return -1;
    if (!b[property] || (isMoment && !b[property].isValid())) return 1;

    if (isMoment) return a[property].isBefore(b[property]) ? -1 : 1;

    return moment(a[property]).isBefore(moment(b[property])) ? -1 : 1;
};

export const formatSilos = (silos) => {
    const formattedSilos = silos?.map((silo) => silo.silo_number).join(', ');
    return formattedSilos || ' - ';
};

/**
 * Get region of current user
 * @returns {string} Region of current user
 */
export const getUserRegion = () => store.getState().persisted.auth.user.data.region;

export const getUserPermission = () => store.getState().persisted.auth.user.data.permissions;

export const getUser = () => store.getState().persisted.auth.user.data;

export const isSuperUser = () => store.getState().persisted.auth.user.data.permissions.includes('IsSuperUser');

export const userHasPermission = (permission) => store.getState().persisted.auth.user.data.permissions.includes(permission);

/**
 * Given a fmmo number, return the name of that fmmo.
 * @param {number} number of fmmo
 * @returns string
 */
export const getFmmoName = (fmmo) => {
    let fmmoName = Object.keys(FMMO).find((key) => FMMO[key] === Number(fmmo));
    fmmoName = fmmoName ? fmmoName.replace(/([A-Z])/g, ' $1').trim() : '';
    return fmmoName;
};

export const getSubMarketName = (subMarket, region) => {
    return districts(region).find((obj) => obj.value === subMarket)?.name ?? '';
};

export const dataTableCSVExport = (title, allColumns, allData) => (columns, data) => {
    const exportColumns = allColumns || columns;
    const exportData = allData || data;
    const csvData = exportData.map((d) => {
        const datum = {};
        exportColumns.forEach((column, i) => {
            if (!column.title) return;

            const columnTitle = column.title;
            const columnField = column.field;
            const value = allColumns ? d[columnField] : d[i];
            if (['Date', 'Sample Tested Date', 'Sample Date', 'Date Sampled', 'Date Tested', 'SPC Test Date', 'Received Date', 'Barcode Received Date', 'Start Date'].includes(columnTitle)) datum[columnTitle] = value ? moment(value).format('MM-DD-YYYY') : null;
            else if (['Delivery Date'].includes(columnTitle)) datum[columnTitle] = value ? moment(value).format('MMM DD') : null;
            else if (['Last Pickup'].includes(columnTitle)) datum[columnTitle] = moment(value).format('MMMM Do, YYYY');
            else if (columnTitle === 'SPC') datum[columnTitle] = value ? Number(value) * 1000 : null;
            else if (columnTitle === 'Time') datum[columnTitle] = moment(value).format('hh:mm A');
            else if (['Time on Farm', 'Since Last Pickup'].includes(columnTitle)) {
                const hours = value < 0 ? Math.ceil(moment.duration(value).asHours()) : Math.floor(moment.duration(value).asHours());
                datum[columnTitle] = hours + moment.utc(value).format(':mm:ss');
            } else if (['time_on_plant', 'time_in_geofence_ms'].includes(columnField)) {
                const hours = value < 0 ? Math.ceil(moment.duration(value).asHours()) : Math.floor(moment.duration(value).asHours());
                datum[columnTitle] = value ? hours + moment.utc(value).format(':mm:ss') : null;
            } else if (['Created', 'Updated'].includes(columnTitle)) datum[columnTitle] = moment(value).format('MMMM, YYYY');
            else if (['geofence_enter_time', 'geofence_exit_time'].includes(columnField)) datum[columnTitle] = value ? moment(value).format('MMM D, YYYY - hh:mm A') : null;
            else if (columnTitle === 'Route Time') datum[columnTitle] = value ? moment(value).format('HH:MM:SS') : '00:00:00';
            else if (columnTitle === 'Time Since Last Pickup') datum[columnTitle] = secondsToTimeConverter(value);
            else if (columnTitle === 'Balanced') datum[columnTitle] = value ? 'Complete' : 'Preliminary';
            else if (['Jump Truck', 'Delivered Outside Geofence', 'Pickup Outside Geofence'].includes(columnTitle)) datum[columnTitle] = value ? 'Y' : 'N';
            else if (['Official', 'Water Added', 'Inhibitor', 'Seal Check'].includes(columnTitle)) datum[columnTitle] = value === true ? 'Yes' : value === false ? 'No' : '-';
            else if (['Rejected'].includes(columnTitle)) datum[columnTitle] = value ? 'Yes' : 'No';
            else if (columnTitle === 'Silos') datum[columnTitle] = formatSilos(value);
            else if (['SAG', 'SAU', 'SNA'].includes(columnTitle)) datum[columnTitle] = value === true ? 'POS' : value === false ? 'NEG' : '-';
            else if (['Sample Type', 'Cow Number'].includes(columnTitle)) datum[columnTitle] = value || '-';
            else if (columnField?.startsWith('fmmo_touch_bases_')) {
                datum[columnTitle] = value?.amount_delivered || '-';
            } else if (columnField === 'processor_fmmo') {
                const fmmos = value?.[0]?.fmmos || [];
                datum[columnTitle] = fmmos.length > 0 ? fmmos.map((fmmo) => `${getFmmoName(fmmo)} (${fmmo === -1 ? 0 : fmmo})`).join(', ') : '-';
            } else if (['Target Pooling Order'].includes(columnTitle)) {
                datum[columnTitle] = value ? value[0]?.fmmos.map((fmmo) => `${getFmmoName(fmmo)} (${fmmo})`).join(', ') : '-';
            } else if (['Target Sub Market'].includes(columnTitle)) {
                datum[columnTitle] = value ? value[0]?.sub_markets.map((subMarket) => getSubMarketName(subMarket, getUserRegion())).join(', ') : '-';
            }
            // This statement checks for column expansion; please make sure its always the last condition. If not, it will override the previous conditions.
            else if (columnField !== 'expand') datum[columnTitle] = value;
        });
        return datum;
    });
    const CSVContent = Papa.unparse(csvData);
    downloadCSV(CSVContent, `${title}.csv`);
};

// todo generalize for all regions
export const hasAboveLabStandard = (lab, region) => {
    let hasAboveStandard = false;
    switch (region) {
        case 'RF':
            hasAboveStandard = hasAboveStandard || (lab.bulk_milk_cell_count && lab.bulk_milk_cell_count > LAB_STANDARDS[region].BMCC_WARNING);
            hasAboveStandard = hasAboveStandard || (lab.bacto_scan && lab.bacto_scan > LAB_STANDARDS[region].BACTO_WARNING);
            hasAboveStandard = hasAboveStandard || (lab.thermo_plate_count && lab.thermo_plate_count > LAB_STANDARDS[region].THERMO_PC_WARNING);
            hasAboveStandard = hasAboveStandard || (lab.temperature && lab.temperature > LAB_STANDARDS[region].TEMPERATURE_ERROR);
            hasAboveStandard = hasAboveStandard || (lab.colostrum_percentage && lab.colostrum_percentage > LAB_STANDARDS[region].COLOSTRUM_ERROR);
            return hasAboveStandard;
        default:
            return false;
    }
};

/**
 *
 * Converts an array of objects into a single map object, key'd by unique id
 *
 * @param {array} arr array of objects to be converted
 * @param {string} key name of unique id property for each object in arr
 * @returns {Object} map object created from elements of arr, accessible by key
 */
export const arrayToMap = (arr, key) => {
    return Object.assign({}, ...arr.map((item) => ({ [item[key]]: item })));
};

/**
 *
 * @param {array} arr array of objects to be converted
 * @param {string} key name of unique id property for each object in arr
 * @returns {Map<unknown, unknown>} map object created from elements of arr, accessible by key
 */
export const arrayToRealMap = (arr, key) => {
    return new Map(arr.map((i) => [i[key], i]));
};

export const mapToArray = (map) => {
    return Object.keys(map).map((entry) => {
        return map[entry];
    });
};

export const isNonEmptyArray = (arr) => Array.isArray(arr) && arr.length;

/**
 *
 * Transforms the seal type from how it is stored in the db, to how it should
 * be displayed on frontend
 * @param {string} seal string of seal type to be transformed
 * @returns {string} string of how the seal will be displayed on frontend for user
 */
export const transformSeals = (seal) => {
    switch (seal) {
        case 'cipVent':
            return 'CIP Vent';
        case 'topDome':
            return 'Top Dome';
        case 'dustCover':
            return 'Rear Dust Cover';
        case 'pumpBox':
            return 'Pump Box';
        case 'tubeEnd':
            return 'Tube End';
        case 'tankerCap':
            return 'Tanker Cap';
        case 'backDoorProbes':
            return 'BackDoorProbes';
        case 'dropoffSample':
            return 'Dropoff Sample';
        default:
            break;
    }
};

export const renameKey = (obj, oldKey, newKey) => {
    if (!!obj && obj.length >= 1) {
        obj.forEach((element) => {
            // eslint-disable-next-line
            delete Object.assign(element, { [newKey]: element[oldKey] })[oldKey];
        });
    }
};

/**
 * Takes any object that uses _id and adds it as an id: _id property,
 * otherwise returns unaltered object.
 * @param {object} obj object to be analyzed and transformed
 * @returns {object} return the object unaltered or with id added to it
 */
export const resolveId = (obj) => {
    // eslint-disable-next-line no-underscore-dangle
    return obj._id ? { id: obj._id, ...obj } : obj;
};

/**
 * Takes an object and tries to resolve a string ID. Assumes if obj is a string
 * that it is the ID.
 * @param {object} obj object to be analyzed and transformed
 * @returns {string} either the id as a string, or the empty string
 */
export const resolveValidStringId = (obj) => {
    if (!obj) return undefined;
    if (typeof obj === 'string') return obj;
    if (typeof obj._id === 'string') return obj._id;
    if (typeof obj.id === 'string') return obj.id;
    if (typeof obj.value === 'string') return obj.value;
    return undefined;
};

export const getRegion = (hostname) => {
    let region = '';

    if (hostname.includes('nl')) {
        region = 'NL';
    } else if (hostname.includes('pei')) {
        region = 'PEI';
    } else if (hostname.includes('cdi')) {
        region = 'CDI';
    } else if (hostname.includes('rf')) {
        region = 'RF';
    } else if (hostname.includes('riverinafresh')) {
        region = 'RF';
    } else if (hostname.includes('uda')) {
        region = 'UDA';
    } else if (hostname.includes('prairiefarms')) {
        region = 'PRAIRIE';
    } else if (hostname.includes('legacymilk')) {
        region = 'LEGACY';
    } else if (hostname.includes('mmpa')) {
        region = 'MMPA';
    } else if (hostname.includes('us-demo')) {
        region = 'US-DEMO';
    } else if (hostname.includes('ca-demo')) {
        region = 'CA-DEMO';
    } else if (hostname.includes('mm-core')) {
        region = 'MM-CORE';
    } else if (hostname.includes('unc')) {
        region = 'UNC';
    } else if (hostname.includes('cacique')) {
        region = 'CACIQUE';
    } else if (hostname.includes('tcj-nebraska')) {
        region = 'TCJ-NEBRASKA';
    } else if (hostname.includes('tcj-liberty')) {
        region = 'TCJ-LIBERTY';
    } else if (hostname.includes('tcj-whiteeagle')) {
        region = 'TCJ-WHITEEAGLE';
    } else if (hostname.includes('tcj-gpda')) {
        region = 'TCJ-GPDA';
    } else if (hostname.includes('tcj-erie')) {
        region = 'TCJ-ERIE';
    } else if (hostname.includes('tcj-kytn')) {
        region = 'TCJ-KYTN';
    } else if (hostname.includes('dfo')) {
        region = 'DFO';
    } else if (hostname.includes('bongards')) {
        region = 'BONGARDS';
    } else if (hostname.includes('psc')) {
        region = 'PSC';
    } else if (hostname.includes('demo')) {
        region = 'DEMO';
    } else {
        region = 'other';
    }

    return region;
};

export const getHostname = (window) => {
    return window.location.hostname.includes('localhost') ? window.location.origin : 'https://'.concat(window.location.hostname);
};

export function getEnvironment(hostname) {
    let env = '';

    if (hostname.includes('staging') || hostname.includes('localhost') || hostname.includes('.stg.') || hostname.includes('experimental')) {
        env = ENVIRONMENTS.stg;
    } else if (hostname.includes('localhost') || hostname.includes('.dev.')) {
        env = ENVIRONMENTS.dev;
    } else {
        env = ENVIRONMENTS.prod;
    }

    return env;
}

export function getECSDeployLevel() {
    const environment = getEnvironment(window.location.hostname);
    switch (environment) {
        case 'staging':
            return 'stg';
        case 'development':
            return 'dev';
        default:
            return 'prod';
    }
}

export function isDemo() {
    return window.location.hostname.includes('demo');
}

export function displayHaulingContractTitle(region) {
    return [Region.MMPA].includes(region) ? 'Supplemental Hauling Contract' : 'Hauling Contract';
}

export function displayHaulingChargeTitle(region) {
    return [Region.MMPA].includes(region) ? 'Supplemental Hauling Charges' : 'Hauling Charges';
}

export function isExperimental() {
    return window.location.hostname.includes('experimental');
}

export const getServerType = (hostname) => {
    let serverType = 'customer';

    if (hostname.includes('demo')) {
        serverType = 'demo';
    } else if (hostname.includes('sandbox')) {
        serverType = 'testing';
    } else if (hostname.includes('experimental')) {
        serverType = 'feature';
    }

    return serverType;
};

export const naturalSortBy = (property) => {
    const sortfn = function (a, b) {
        try {
            return _.get(a, property, a).trim().localeCompare(_.get(b, property, b).trim(), undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`Something went wrong whilst creating sort parameters. Does the property ${property} you are sorting by exist?`);
            return function () {};
        }
    };
    return sortfn;
};

export const naturalSort = (arr, property) => {
    const workingArr = [...arr];
    workingArr.sort(naturalSortBy(property));
    return workingArr;
};

export const capitalize = (s) => {
    // eslint-disable-next-line no-console
    if (!s) console.warn('Careful! You are passing a null, undefined or empty string to a function that performs string operations and expects a valid string.');
    return s && s[0].toUpperCase() + s.slice(1);
};

/**
 * coerce string to a boolean depending on if it is or is not truthy => yes, true, on 1 are considered truthy (capitalization not considered).
 * if not of type string, will try to coerce to boolean without consideration of string content
 *
 * @param {string} s string to be coerced to boolean
 * @returns boolean
 */
export function parseBool(s) {
    const truthyStrings = ['on', 'yes', 'true', '1'];
    if (typeof s !== 'string') return !!s;
    return truthyStrings.includes(s.toLowerCase());
}

export const hasNonNullField = (obj, field) => {
    return field in obj && obj[field] !== null;
};

export const hasNonNullFields = (obj, fields) => {
    let nonNullFields = true;
    fields.forEach((field) => {
        if (!hasNonNullField(obj, field)) {
            nonNullFields = false;
        }
    });
    return nonNullFields;
};

/**
 * Takes in object, returns clone of object with all null keys s.t obj[key] === null removed
 *
 * @param {Object} obj
 * @returns {Object}
 */
export const stripNulls = (obj) => {
    return Object.entries(obj)
        .filter(([a, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

/**
 * Clamp a number within a range
 * @param {number} num number to clamp
 * @param {number} min range minimum
 * @param {number} max range maximum
 * @returns {number} num if within range, min if below range, max if above range
 */
export const clamp = (num, min, max) => Math.max(min, Math.min(num, max));

/**
 * returns number passed as parameter if greater than zero, otherwise returns zero
 * @param {number} num
 * @returns {num} number passed as parameter if greater than zero, otherwise zero
 */
export const zeroBounded = (num) => {
    if (!num) return 0;
    return Math.max(0, num);
};

/**
 * Calculate mean of array of numbers
 * @param {[number]} nums array of numbers to calc mean
 * @returns {number} mean of nums
 */
export const mean = (nums) => {
    const filteredNums = nums.filter((n) => !Number.isNaN(Number(n)));
    if (!filteredNums.length) return 0;
    const sum = filteredNums.reduce((a, b) => a + b, 0);
    const avg = sum / filteredNums.length;
    return avg;
};

export const boundedRange = (value, high, low) => {
    // add to utils
    if (_.inRange(value, high, low)) return value;
    if (value < low) return low;
    return high;
};

export const canToggleSelectedDateRange = (region) => ['CDI', 'UDA', 'RF', 'PRAIRIE', 'LEGACY', 'MMPA'].includes(region);

export const requiresVolumeInLbs = (region) => {
    switch (region) {
        case 'CDI':
        case 'UDA':
        case 'BORDEN':
        case 'PRAIRIE':
        case 'LEGACY':
        case 'MMPA':
            return true;
        default:
            return false;
    }
};

export const requiresDistanceInMi = (region) => {
    switch (region) {
        case 'CDI':
        case 'UDA':
        case 'BORDEN':
        case 'PRAIRIE':
        case 'LEGACY':
        case 'MMPA':
            return true;
        default:
            return false;
    }
};

/**
 * Check if error message starts with lab report already exists identifier: 'labRptExists@'
 * @param {string} message
 * @returns boolean
 */
export const isLabAlreadyExistsMsg = (message) => {
    return message?.toString().startsWith('labRptExists@');
};

export const isPickupLabAlreadyExistsMsg = (message) => {
    return message?.toString().startsWith('A lab report already');
};

/**
 * Returns the error message minus lab report already exists identifier: 'labRptExists@' and trims trailing space
 * @param {string} message
 * @returns string
 */
export const getLabAlreadyExistsMsg = (message) => {
    return message?.toString().split('labRptExists@')[1].trim();
};

/**
 * Function that formats number eg. 1000.00 -> 1,000.00
 * @param {number} number that you want to format
 * @returns formatted number as string
 */
export const formatNumber = (number) => {
    const negative = number < 0;
    const [whole, decimal] = Math.abs(number).toString().split('.');
    const formattedWhole = whole
        .split('')
        .reverse()
        .map((c, i) => (i % 3 === 2 && i !== whole.length - 1 ? `,${c}` : c))
        .reverse()
        .join('');
    const result = decimal ? `${formattedWhole}.${decimal.substring(0, 3)}` : formattedWhole;
    return negative ? `-${result}` : result;
};

export function formatUSD(number) {
    const value = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
    return value === '$NaN' ? '' : value;
}

export const buildExportButtons = (title, columns, data) => {
    return [
        {
            label: 'Export visible columns as CSV',
            exportFunc: dataTableCSVExport(title),
        },
        {
            label: 'Export all columns as CSV',
            exportFunc: dataTableCSVExport(title, columns, data),
        },
    ];
};

export const getFMMOSForRegion = (region) => {
    switch (region) {
        case 'MMPA':
            return [0, 1, 5, 7, 30, 32, 33];
        case 'PRAIRIE':
            return [0, 5, 7, 30, 32, 33];
        case 'UNC':
            return [0, 1, 127, 33];
        case 'TCJ-NEBRASKA':
            return [30];
        case 'TCJ-ERIE':
            return [33];
        case 'TCJ-WHITEEAGLE':
            return [6, 33];
        case 'TCJ-GPDA':
            return [30, 32];
        case 'TCJ-LIBERTY':
            return [1];
        case 'TCJ-KYTN':
            return [7];
        default:
            return [0, 1, 5, 6, 7, 30, 32, 33, 51, 124, 126, 131];
    }
};

export const getSubmarketsForRegion = (region) => {
    switch (region) {
        case 'UNC':
            return ['UNC FO1', 'UNC WNY', 'UNC FO1 (DFA 9c)', 'UNC FO33', 'DFA WNY (UNC 9c)', 'DFA FO1 (DFA 9c)', 'Purchased Milk', 'Unregulated'];
        default:
            return [];
    }
};

/**
 * Helper function to automatically download a pdf
   Ex. When clicking 'download' on report the response is a url of an s3 bucket where the generated
   pdf is stored, passing the url into this function will download that pdf for the user.
 * @param {string} pdfUrl
 */
export const downloadPdfFromUrl = (pdfUrl) => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.click();
};

/**
 * Used to define custom search functionality for dates in the config file for tables
 * @param {string} searchTerm
 * @param {moment.Moment} dateField
 * @returns {boolean}
 */
export const searchDateField = (searchTerm, dateField) => {
    return dateField?.format('MMM DD').toLowerCase().includes(searchTerm?.toLowerCase());
};

/**
 * Used to define custom search functionality for 'string' fields in the config file for tables
 * @param {string} searchTerm
 * @param {string} textField
 * @returns {boolean}
 */
export const searchTextField = (searchTerm, textField) => {
    return textField?.toLowerCase().includes(searchTerm?.toLowerCase());
};

/**
 * Formats db value into readable text
 * @param {string} basis
 * @returns {string}
 */
export const formatAssignmentBasis = (basis) => {
    if (!basis) return '-';
    switch (basis) {
        case 'cwt':
        case 'gross_price':
            return 'CWT';
        case 'per_advance_day':
            return 'Weighted Per Advance Day';
        case 'percent_net_pay':
            return 'Percent of Net Pay';
        case 'percent_gross_pay':
            return 'Percent of Gross Pay';
        case 'percent_cwt_milk_value':
            return 'Percent of CWT Milk Value';
        case 'weekly_average_previous_month_permanent_deductions':
            return 'Weekly Average of Previous Months Permanent Deductions';
        case 'half_previous_month_permanent_deductions':
            return 'Half of Previous Months Permanent Deductions';
        case 'lbs_with_cwt_rate':
            return 'Lbs With CWT Rate';
        default:
            return _.capitalize(basis);
    }
};

export const formatMerchandisePurchaseType = (purchase_type) => {
    if (!purchase_type) return '-';
    switch (purchase_type) {
        case 'producer_sales':
            return 'Producer Sales';
        case 'hauler_sales':
            return 'Hauler Sales';
        case 'hauler_commissions':
            return 'Hauler Commissions';
        case 'hauler_over_short':
            return 'Hauler Over Short';
        default:
            return _.capitalize(purchase_type);
    }
};

export const FmmoNameAndNumber = {
    Depooled: 0,
    Northeast: 1,
    Appalachian: 5,
    Florida: 6,
    Southeast: 7,
    'Upper Midwest': 30,
    Central: 32,
    Mideast: 33,
    California: 51,
    'Pacific Northwest': 124,
    Southwest: 126,
    Arizona: 131,
};

export const PlantQualifiedStatus = {
    PoolDistributingPlant: 'pool_distributing_plant',
    PoolSupplyPlant: 'pool_supply_plant',
    PoolSupplySystemPlant: 'pool_supply_system_plant',
    PoolUnitPlant: 'pool_unit_plant',
    PartiallyRegulatedDistributingPlant: 'partially_regulated_distributing_plant',
    CooperativeStateReportingUnit: 'cooperative_state_reporting_unit',
    StateReportingUnit: 'state_reporting_unit',
};

/**
 * Sums specified properties in an array of objects.
 * @param {Array} array of objects
 * @param {Array<string>} array of the properties on the objects that you want summed
 * @returns object
 */
export const sumAllProperties = (data, props) => {
    return data.reduce((r, o) => Object.fromEntries(props.map((k) => [k, (r[k] || 0) + o[k]])), {});
};

/**
 * Returns a formatted object required in calculateComponents
 * @param {Array} labReports An array of lab report objects
 * @param {string} region Uses region to determine which grouping to use
 * @returns Array
 */
export const formatLabsForComponentCalculation = (labReports, region) => {
    const groupedLabReports = region === 'BORDEN' ? _.groupBy(labReports, 'sample_barcode') : _.mapValues(_.groupBy(labReports, 'producer_id'), (o) => _.groupBy(o, (e) => moment(e.date).format('YYYYMMDD')));
    const labReportsSet = [
        ...new Set(
            Object.values(groupedLabReports)
                .map((obj) => {
                    return Object.values(obj);
                })
                .flat()
        ),
    ].flat();
    const labsByPickupIdMap = arrayToRealMap(labReportsSet, 'pickup_id');
    return [groupedLabReports, labsByPickupIdMap];
};

export const getWeekDateRange = (date) => {
    const payWeeks = [1, 8, 16, 24];
    const dayOfWeek = moment(date).date();

    let weekStartDate;
    let weekEndDate;
    if (dayOfWeek < payWeeks[1]) {
        weekStartDate = moment(date).startOf('month').toDate();
        weekEndDate = moment(date)
            .set('date', payWeeks[1] - 1)
            .endOf('day')
            .toDate();
    } else if (dayOfWeek < payWeeks[2]) {
        weekStartDate = moment(date).set('date', payWeeks[1]).startOf('day').toDate();
        weekEndDate = moment(date)
            .set('date', payWeeks[2] - 1)
            .endOf('day')
            .toDate();
    } else if (dayOfWeek < payWeeks[3]) {
        weekStartDate = moment(date).set('date', payWeeks[2]).startOf('day').toDate();
        weekEndDate = moment(date)
            .set('date', payWeeks[3] - 1)
            .endOf('day')
            .toDate();
    } else {
        weekStartDate = moment(date).set('date', payWeeks[3]).startOf('day').toDate();
        weekEndDate = moment(date).endOf('month').toDate();
    }
    return [weekStartDate, weekEndDate];
};

/**
 * Converts index into pay week title
 * @param {string} index
 * @returns string
 */
export const getPayWeek = (index) => {
    // eslint-disable-next-line default-case
    switch (index) {
        case 1:
            return '1 -  7';
        case 8:
            return '8 - 15';
        case 16:
            return '16 - 23';
        case 24:
            return '24 - EOM';
    }
};

/**
 * Converts camel case to sentence case
 * @param {string} string string value
 * @returns string
 */

export const toSentenceCase = (string) => {
    if (!string) return undefined;
    const interim = string.replace(/_/g, ' ').replace(' id', '');
    return interim.slice(0, 1).toUpperCase() + interim.slice(1);
};

/**
 * Converts a string to title case, split on separator
 * @param {string} string string to convert
 * @param {string} separator char to separate on
 * @returns {string}
 */
export const toTitleCase = (string, separator = ' ') => {
    if (!string) return undefined;
    return string
        .split(separator)
        .map((word) => capitalize(word))
        .join(' ');
};

export const toSnakeCase = (string) => {
    if (!string) return undefined;
    return string
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join('_');
};

/**
 * Uploads a file to S3
 * @param { Blob | File } file the file to be uploaded
 * @returns { { string, string } } { data, errors }
 */
export const handleFileUpload = async (file) => {
    return new Promise((resolve, reject) => {
        const type = _.lowerCase(path.extname(file.name));
        const returnValue = {};
        if (type === 'jpg' || type === 'jpeg' || type === 'png') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                uploadImage({ base64: reader.result })
                    .then((response) => {
                        returnValue.data = response.imageInfo.key;
                        resolve(returnValue);
                    })
                    .catch(() => {
                        returnValue.error = 'There was a problem uploading your file. Please contact support.';
                        resolve(returnValue);
                    });
            };
        } else if (type === 'pdf') {
            const formData = new FormData();
            formData.append('file', file);
            return uploadFile(formData)
                .then((response) => {
                    returnValue.data = response.data.key;
                    resolve(returnValue);
                })
                .catch((err) => {
                    returnValue.error = 'There was a problem uploading your file. Please contact support.';
                    resolve(returnValue);
                });
        } else {
            returnValue.error = 'Please provide a valid image or .pdf file.';
            resolve(returnValue);
        }
    });
};

export const addPageTracking = (pageName) => {
    try {
        if (window.location.hostname !== 'localhost') {
            datadogRum.startView(pageName);
            FullStory.setVars('page', { pageName });
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

/**
 * Users may enter a url in a variety of forms such as google.com, www.google.com etc.
 * This function formats the url to the following format: http://www.google.com.
 *
 * @param {string} linkUrl - the url entered byt hte user.
 * @returns {string} a formatted url.
 */
export const formatUrl = (linkUrl) => {
    let formattedLink = '';
    if (!linkUrl.includes('http')) {
        formattedLink += 'http://';
    }

    formattedLink += linkUrl;
    return formattedLink;
};

/**
 * Parses an item to get the amount of slip fields with valid values
 * Slips Checked for: 'slip', 'seal_slip, 'manifest_slip', 'scale_slip', 'coa_slip'
 *
 * @param {object} item - pickup, dropoff or other item with slips.
 * @return {number} amount of slips
 */
export const getNumSlips = (item) => {
    if (!item) return 0;

    let count = 0;

    if (item.slip) count += 1;
    if (item.metered_slip) count += 1;
    if (item.seal_slip) count += 1;
    if (item.manifest_slip) count += 1;
    if (item.scale_slip) count += 1;
    if (item.coa_slip) count += 1;
    if (item.scale_ticket_slip) count += 1;

    return count;
};

/**
 * Returns a error message for the user forms, customized to the usertype and request type of the form.
 * @param err error that was thrown
 * @param userType the type of user that was being added: admin, driver, processor, producer
 * @param {('add'|'edit')} mode request type of the form: add || edit
 * @returns {string} formatted message to be displayed to the user
 */
export const formatUserErrorMessage = (err, userType, mode) => {
    let message = `An error occurred ${mode}ing a ${mode === 'add' ? 'new' : ''} ${userType} user. `;
    if (err.statusCode === 400) {
        message += err.message;
    }
    return message;
};

/**
 * Parses a model with the select components options for the submission
 *
 * @param {object} model
 * @returns
 */
export const setSubmitModelSelectValues = (model) => {
    const submitModel = { ...model };

    Object.keys(submitModel).forEach((key) => {
        if (submitModel[key]?.value !== undefined) {
            submitModel[key] = submitModel[key].value;
        }
    });

    return submitModel;
};

export const POUNDS_TO_GALLONS_CONVERSION_RATE = 8.6;

/**
 * @param {number} num
 * @param {number} decimals
 * @param {string} defaultValue
 * @returns formatted number as string with 2 decimals ex: 10.00 or -10.00
 */
export const formatNumberAsFloatStr = (num, decimals, defaultValue = '-') => {
    return !num
        ? defaultValue
        : new Intl.NumberFormat({
              minimumFractionDigits: decimals,
              maximumFractionDigigts: decimals,
          }).format(num);
};

/**
 * @param {number} num
 * @param {string} defaultValue
 * @returns formatted number as currency with 2 decimals ex: $10.00 or -$10.00
 */
export const formatNumberAsCurrency = (num, defaultValue = '-') => {
    const formattedDefault = defaultValue === 0 || defaultValue === '0' ? '$0.00' : defaultValue;
    return !num
        ? formattedDefault
        : Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
          }).format(num);
};

/**
 * Returns unformatted phone number
 * @param {string} phone string only contains numbers (ex: +1 (915) 303-6552)
 * @returns {string} unformatted phone number (ex: 19153036552)
 */
export const unformattedPhoneNumber = (phone) => {
    return phone ? phone.replace(/\D/g, '') : '';
};

/**
 * Returns the country code for the region
 * @param {string} region
 * @returns
 */
export const getCountryCode = (region) =>
    ({
        NL: 'ca',
        PEI: 'ca',
        SM: 'us',
        RF: 'au',
        BORDEN: 'us',
        CDI: 'us',
        UDA: 'us',
        PRAIRIE: 'us',
        LEGACY: 'us',
        MMPA: 'us',
    }[region]);

/*
 * Returns formatted phone number by using libphonenumber-js
 * @param {string} phone string only contains numbers (ex: 19153036552)
 * @param {('NATIONAL' | 'INTERNATIONAL' | 'E.164' | 'RFC3966' | 'IDD')} format the format type of the phone
 * @returns {string} formatted phone number (ex: +1 (915) 303-6552)
 */
export const formatPhoneNumber = (phone, format = 'INTERNATIONAL') => {
    try {
        if (!phone) {
            return '';
        }
        const unformattedPhone = `+${unformattedPhoneNumber(phone)}`;
        const parsedPhoneNumber = parsePhoneNumber(unformattedPhone);
        if (isValidPhoneNumber(unformattedPhone, parsedPhoneNumber?.country)) {
            return parsedPhoneNumber.format(format);
        }
        const defaultCountryCode = getCountryCode(getUserRegion())?.toUpperCase() ?? 'US';
        const callingCode = getCountryCallingCode(defaultCountryCode);
        const formattedPhone = `+${callingCode}${unformattedPhoneNumber(phone)}`;
        const defaultPhoneNumber = parsePhoneNumber(formattedPhone, defaultCountryCode);
        return defaultPhoneNumber.format(format);
    } catch (err) {
        return '';
    }
};

export const farmLabelByRegion = (region) => (['MMPA'].includes(region) ? 'Producer' : 'Physical');

/**
 * Returns an array of numbers of length <size>, starting from 0 or <startAt>
 * @param {*} size length of array to be returned
 * @param {*} startAt intial value
 * @returns {number[]}
 */
export const range = (size, startAt = 0) => [...Array(size).keys()].map((i) => i + startAt);

export function CustomInBuiltTableFilter(props) {
    const [value, setValue] = useState();
    return (
        <div style={{ marginRight: '12px', minWidth: '100px' }}>
            <Autocomplete
                value={value}
                onChange={(event, optionSelected) => {
                    setValue(optionSelected);
                    props.onFilterChanged(props.columnDef.tableData.id, optionSelected?.value);
                }}
                options={props.columnDef.options}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label={props.label} data-testid={`${props.columnDef.title.replace(' ', '')}AutoComplete`} />}
            />
        </div>
    );
}

export const milkCategoryTypes = {
    member_producer: 'Member Producer',
    outside_milk: 'Outside Milk',
    comingled: 'Co-mingled',
};

export const formatEquityApprovalPermissions = {
    DpadStatement: 'DPAD Statement',
    RetainedEarningsRevolvement: 'Certificate of Equity Revolvement',
    PatronageStatement: 'Patronage/Certificate of Equity',
    DividendStatement: 'Dividend Statement',
    CommonStockMassPayoutStatement: 'Common Stock Mass Payout Statement',
    '1099-PATR': '1099-PATR',
    MISC1099: '1099-MISC',
    '1099-DIV': '1099-DIV',
};

export const booleanOrNullFields = [
    {
        name: 'Not Done',
        value: null,
    },
    {
        name: 'Not Found',
        value: false,
    },
    {
        name: 'Found',
        value: true,
    },
];

/**
 * Formats a number with the requested number of decimals
 * @param {*} num - number to format
 * @param {*} decimals - requested decimals
 * @returns - formatted number as a string
 */
export const formatNumberWithDecimal = (num, decimals) => {
    const formattedNum = Number(num).toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });

    return formattedNum;
};

export const parsePrairieBarcodeSeqNum = (barcode) => {
    const PRAIRIE_BARCODE_LENGTH = 38;

    if (barcode?.startsWith('97') || barcode?.startsWith('99')) {
        // Skip the first two digits and return the other digits as a sequence number
        return [barcode?.substring(2)];
    }
    let seq_number;
    if (barcode?.length === PRAIRIE_BARCODE_LENGTH) {
        seq_number = barcode?.substring(14, 18);
    } else {
        // Converting to a number removes leading zeros
        seq_number = barcode?.substring(7);
    }

    return [seq_number];
};

export const barcodeLastFourDigits = (sample_barcode) => {
    const getLastFourDigits = (barcode) => {
        if (barcode?.length >= 4) {
            return barcode.slice(-4);
        }
        // Handle cases where the barcode has less than four digits
        return barcode;
    };

    const lastFourDigits = getLastFourDigits(sample_barcode || '');

    return lastFourDigits;
};

export const displayBarcodeBasedOnRegion = (region, sample_barcode) => {
    switch (region) {
        case 'MMPA':
            return barcodeLastFourDigits(sample_barcode);
        default:
            return sample_barcode;
    }
};

/**
 * Deletes a (nested) property from an object
 * @param {*} obj - target object
 * @param {*} fieldPath - field path
 */
export const deleteObjectField = (obj, fieldPath) => {
    if (!obj || !fieldPath) {
        return;
    }

    // eslint-disable-next-line no-param-reassign
    fieldPath = fieldPath.split('.');

    for (let i = 0; i < fieldPath.length - 1; i++) {
        // eslint-disable-next-line no-param-reassign
        obj = obj[fieldPath[i]];

        if (typeof obj === 'undefined') {
            return;
        }
    }

    // eslint-disable-next-line no-param-reassign
    delete obj[fieldPath.pop()];
};

/**
 * QA Team function used to generate test ids in a consistent manner
 * @param title - unique identifier for the component
 * @param componentType - Type of component
 */
export const generateTestId = (title, componentType) => {
    return title ? `${title?.toLowerCase().replace(/ /g, '-')}-${componentType}` : `no-name-${componentType}`;
};

/**
 * Returns the value from a reference string. Reference string is a dot separated string that represents a path to a value in an object.
 * If the reference string does not contain '.', it will return the value from the object with the key of the reference string.
 *
 * @param {string} referenceString - reference string
 * @param {object} referenceData - reference data
 * @returns {*} value from reference string
 */
export const getValueFromReferenceString = (referenceString, referenceData) => {
    if (!referenceString || !referenceData || typeof referenceString !== 'string') {
        return null;
    }

    const referenceArray = referenceString.split('.');
    if (referenceArray.length === 1) {
        return referenceData[referenceString];
    }

    return referenceArray.reduce((p, c) => p?.[c], referenceData);
};

/**
 * This function filters through the list of both child + parent producers
 * and displays only Parent Producers.
 */
export const filterProducersByParentAndChild = (producers) => {
    const parentProducers = [];
    const childProducers = [];
    producers.forEach((producer) => {
        if (producer.split_producer === true) {
            childProducers.push(producer);
        } else {
            parentProducers.push(producer);
        }
    });
    return [parentProducers, childProducers];
};

/**
 * Returns array of all non-object values in a nested object
 * @param {{}} obj
 * @returns {[]}
 */
export function extractValues(obj) {
    return Object.values(obj)
        .map((val) => {
            if (typeof val === 'object') {
                return extractValues(val);
            }
            return val;
        })
        .flat();
}

/**
 *
 * return arr without values
 *
 * @param {[]} arr
 * @param {[]} values
 * @returns {[]}
 */
export function omitValues(arr, values) {
    return arr.filter((val) => !values.includes(val));
}

/**
 * Extracts the file name, base file name, and base file name without extension
 * from an AWS S3 signed URL.
 *
 * @param {string} signedURL - The signed URL of the file in AWS S3.
 * @returns {{
 *   bucketUrl: string,
 *   bucketPath: string,
 *   fileName: string,
 *   baseFileName: string,
 *   baseFileNameExtensionless: string,
 *   accessKeyId: string,
 *   expires: string,
 *   signature: string
 * }} An object containing the extracted information.
 */
export function extractS3InfoFromSignedURL(signedURL) {
    const urlParts = signedURL.split('?');
    const baseUrl = urlParts[0];
    const queryParams = urlParts[1];

    const pathParts = baseUrl.split('/');

    const bucketUrl = pathParts[2];
    const bucketParts = bucketUrl.split('.');
    const bucketPath = bucketParts[0];

    const filePath = pathParts.slice(3).join('/');

    const baseFileName = filePath.split('/').pop();
    const lastDotIndex = baseFileName.lastIndexOf('.');
    const baseFileNameExtensionless = lastDotIndex !== -1 ? baseFileName.substring(0, lastDotIndex) : baseFileName;

    const queryParamsList = queryParams.split('&');
    let accessKeyId;
    let expires;
    let signature;

    queryParamsList.forEach((param) => {
        const [key, value] = param.split('=');
        switch (key) {
            case 'AWSAccessKeyId':
                accessKeyId = value;
                break;
            case 'Expires':
                expires = value;
                break;
            case 'Signature':
                signature = value;
                break;
            default:
            // Add more cases for other query parameters if needed
        }
    });

    return {
        bucketUrl,
        bucketPath,
        filePath,
        baseFileName,
        baseFileNameExtensionless,
        accessKeyId,
        expires,
        signature,
    };
}

export const isTCJRegion = (region) => {
    return TCJ_REGIONS.includes(region);
};

/**
 * Logs a message to the console with the specified severity level only in
 * staging and development environments
 *
 * @param {*} parameters
 * @param {string} parameters.message Message to log
 * @param {('log'|'info'|'warn'|'error')} parameters.severity Severity level to log at
 * @returns {void}
 */
export const debugLog = ({ message = 'log', severity }) => {
    /* eslint-disable no-console */
    // Only log to console in staging and development
    if ([ENVIRONMENTS.stg, ENVIRONMENTS.dev].includes(getEnvironment(window.location.hostname))) {
        const logger = console[severity];
        if (!logger) {
            console.error(`Invalid console severity level: ${severity}`);
            return;
        }
        logger(message);
    }
    /* eslint-enable no-console */
};

export const haulingStopCategory = [
    { name: 'LTL (Less than Load)', value: 'less_than_load' },
    { name: 'Efficient', value: 'efficient' },
    { name: 'Ultra Efficient', value: 'ultra_efficient' },
    { name: 'Direct Load', value: 'direct_load' },
    { name: 'LTL (Non-electric)', value: 'LTL_non_electric' },
];

export const getAllFederalOrders = (isPoolingDecisions = false) => {
    const fmmos = [
        { name: 'Federal Order 0', value: '0' },
        { name: 'Federal Order 1', value: '1' },
        { name: 'Federal Order 5', value: '5' },
        { name: 'Federal Order 6', value: '6' },
        { name: 'Federal Order 7', value: '7' },
        { name: 'Federal Order 30', value: '30' },
        { name: 'Federal Order 32', value: '32' },
        { name: 'Federal Order 33', value: '33' },
        { name: 'Federal Order 51', value: '51' },
        { name: 'Federal Order 124', value: '124' },
        { name: 'Federal Order 126', value: '126' },
        { name: 'State Order 127', value: '127' },
        { name: 'Federal Order 131', value: '131' },
        { name: 'Federal Order 134', value: '134' },
        { name: 'Nebraska', value: 'nebraska' },
    ];

    if (isPoolingDecisions) fmmos.pop();
    return fmmos;
};

export const getAllFOsForProcessorFormDropdown = () => {
    return [
        { id: 'FO_0', name: 'FO 0' },
        { id: 'FO_1', name: 'FO 1' },
        { id: 'FO_5', name: 'FO 5' },
        { id: 'FO_6', name: 'FO 6' },
        { id: 'FO_7', name: 'FO 7' },
        { id: 'FO_30', name: 'FO 30' },
        { id: 'FO_32', name: 'FO 32' },
        { id: 'FO_33', name: 'FO 33' },
        { id: 'FO_51', name: 'FO 51' },
        { id: 'FO_124', name: 'FO 124' },
        { id: 'FO_126', name: 'FO 126' },
        { id: 'FO_127', name: 'FO 127' },
        { id: 'FO_131', name: 'FO 131' },
        { id: 'FO_134', name: 'FO 134' },
        { id: 'FO_nebraska', name: 'FO Nebraska' },
    ];
};

export const getFMMOBasedOnRegionForProcessorForm = (region) => {
    // Define a mapping of region names to FMMO IDs
    const regionMappings = {
        MMPA: [1, 5, 7, 30, 33],
        PRAIRIE: [0, 5, 7, 30, 32, 33],
        UNC: [0, 1, 5, 6, 7, 30, 32, 33, 51, 124, 126, 127, 131],
        UDA: [33],
        'US-DEMO': [33],
        default: [0, 1, 5, 6, 7, 30, 32, 33, 51, 124, 126, 127, 131, 134],
        TCJ: [1, 5, 6, 7, 30, 32, 33, 51, 126, 131, 134],
    };

    // Retrieve FMMO IDs based on the region, if not found use default
    const fmmoIds = regionMappings[region] || regionMappings.default;

    // Map the FMMO IDs to objects with id and name properties
    return fmmoIds.map((id) => ({
        id: id.toString(),
        name: id.toString(),
    }));
};

export const pluralize = (word, quantity = 0) => {
    const isPlural = quantity > 1;
    if (word.endsWith('s')) return word;
    if (isPlural) {
        if (word.endsWith('y')) return `${word.slice(0, -1)}ies`;
        return `${word}s`;
    }
    return word;
};

export const omittedProducersRF = ['933', '933-1', '936', '952', '942', '961', '649', '628', '963', '967', '646', '690', '959', '962', '964', '965', '965-1'];

export const classTypes = [
    { id: 'CLASS_1', name: 'Class 1' },
    { id: 'CLASS_2', name: 'Class 2' },
    { id: 'CLASS_3', name: 'Class 3' },
    { id: 'CLASS_4', name: 'Class 4' },
    { id: 'CLASS_9C', name: 'Class 9C' },
    { id: 'CLASS_BLEND', name: 'Blend' },
    { id: 'CLASS_CUSTOM', name: 'Custom' },
    { id: 'CLASS_NA', name: 'Class N/A' },
];

// Convert array to an object with numbered keys
export const convertArrayToObject = (array) => {
    if (!array || !array.length) return {};
    return array.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
    }, {});
};

export const isHauler = () => getRole() === 'transport';
export const isProcessor = () => getRole() === 'processor';

export const isDateInWindow = (date, windows) => {
    if (!date || !windows) return false;

    const momentDate = moment.isMoment(date) ? date : moment(date);
    const dayOfWeek = momentDate.day();
    const totalMinutes = momentDate.hours() * 60 + date.minutes();

    // if no window for that day of the week, return false
    if (!windows[dayOfWeek]) return false;

    const dayWindows = windows[dayOfWeek];

    return dayWindows.some((window) => totalMinutes >= window.start && totalMinutes <= window.end);
};

export const getStatesForSelectOptions = (key = 'id') => {
    const states = listOfStates.only50();

    return states.map((value) => ({ [key]: value.usps, name: value.usps }));
};
