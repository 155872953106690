import { string, number } from 'yup';
import { resolveValidStringId } from '../../../utils';

/**
 * Error Messages for the schema
 */
export const SCHEMA_MESSAGES = {
    /**
     * Message if a required field is in error
     * @param {string} field (optional) the field in question
     * @returns formatted error message for required field
     */
    requiredMessage: (field) => `${field ?? ''}${field ? ' is ' : ''}Required`,

    /**
     * Message if a number fields is below the min
     * @param {string} field (optional) the field in question
     * @returns formatted error message for required field
     */
    minMessage:
        (field) =>
        ({ min }) =>
            `${field ?? 'Value'} must be greater than or equal to ${min}`,

    /**
     * Message if a number field is above the max
     * @param {string} field (optional) the field in question
     * @returns formatted error message for required field
     */
    maxMessage:
        (field) =>
        ({ max }) =>
            `${field ?? 'Value'} must be less than or equal to than ${max}`,
};

/**
 * Checks for an empty string and returns the assigned value
 * @param {string} str to be checked for empty string
 * @param {any} [newValue] the value to replace the empty string with
 * @returns {any} what to transform an empty string into
 */
export const resolveEmptyString = (str, newValue = null) => {
    if (!str || !str.length) return newValue;
    return str;
};

/**
 * Validates that a number is not NaN
 * @param {any} value value to transform to null if it causes a NaN error
 * @returns undefined or the number
 */
export const transformNaNToUndefined = (value) => (Number.isNaN(value) ? undefined : value);

/**
 * Transforms a null value into undefined
 * @param {any} value The value to check and transform
 * @returns {undefined|any} Returns undefined if the value is null, otherwise returns the original value
 */
export const transformNullToUndefined = (value) => (value === null ? undefined : value);

/**
 * Transforms an empty string to null
 * @param {any} value The value to check and transform
 * @returns {undefined|any} Returns null if the value is an empty string, otherwise returns the original value
 */
export const transformEmptyStringToNull = (value) => (value === '' ? null : value);

/**
 * Return a yup string with a transform that will try and resolve
 *  a string id for an object
 * @param {object} params
 * @param {boolean} params.required - whether the field is required or not
 * @param {boolean} params.optional - whether the field is optional
 * @param {string} params.field - the field name
 * @returns a yup string validator
 */
export function resolveIdString({ required, optional, field, forceNull = false }) {
    const idString = string().transform((value) => {
        const resolved = resolveValidStringId(value, forceNull);
        return forceNull ? resolveEmptyString(resolved) : resolved;
    });
    if (required) return idString.required(SCHEMA_MESSAGES.requiredMessage(field));

    if (optional) {
        return idString.optional();
    }
    return idString;
}

export function pfTareWeight() {
    return number()
        .required()
        .min(0)
        .test('isSmallEnough', 'Tare Amount must be less than Gross Amount', function (value) {
            return value < this.parent.scale_weight;
        });
}

/**
 * Returns a yup number validator for numbers between min and max values
 *  Min and Max are not required, if they are supplied it will validate
 *  Min/Max respectively. If not supplied it return a number that transforms NaNToUndefined
 * @param {object} params
 * @param {string} params.field (optional) the field name
 * @param {number} params.min (optional) the minimum number
 * @param {number} params.max (optional) the maximum number
 * @param {boolean} params.required whether the field is required or not
 * @param {boolean} params.optional whether the field is optional
 *
 * @throws {TypeError} if min or max are not numbers
 * @throws {RangeError} if min is greater than max
 *
 * @returns a yup string validator
 */
export function rangeNumber({ field, min, max, required, optional }) {
    let numberFunction = number().transform(transformNaNToUndefined);

    // Validate input
    if (min && (Number.isNaN(Number(min)) || !Number.isFinite(Number(min)))) throw new TypeError('Min value is not a number');
    if (max && (Number.isNaN(Number(max)) || !Number.isFinite(Number(max)))) throw new TypeError('Max value is not a number');
    if (min && max && min > max) throw new RangeError('Min is lower than max.');

    // Append resolvers
    // These need to be null checks because zero is falsy
    if (min != null) numberFunction = numberFunction.min(min, SCHEMA_MESSAGES.minMessage(field));
    if (max != null) numberFunction = numberFunction.max(max, SCHEMA_MESSAGES.maxMessage(field));
    if (required) return numberFunction.required(SCHEMA_MESSAGES.requiredMessage(field));
    if (optional) return numberFunction.optional();

    return numberFunction;
}

/**
 * Returns a yup number validator for numbers between 0-100
 *
 * @see {@link rangeNumber}
 * @param {object} params
 * @param {string} params.field the field name
 * @param {boolean} params.required whether the field is required or not
 * @param {boolean} params.optional whether the field is optional
 * @returns a yup string validator
 */
export function percentNumber({ field, required, optional }) {
    return rangeNumber({ field, min: 0, max: 100, required, optional });
}
