import { useContext } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useScheduleSettings } from '../../../main/apps/FunctionalApps/schedule-list-view/ScheduleSettingsStore';
import { NotificationContext } from '../../../NotificationContext';
import useExpress, { expressMutation } from '../../../useExpress';

const getUsersQuery = (queryParams) => {
    const params = {
        where: {
            deleted_at: { eq: null },
            ...queryParams,
        },
    };
    const endpoint = '/users';
    return useExpress({ endpoint, params });
};

export const useFetchUsers = ({ enabled = true, ...queryParams } = {}) => {
    const queryFn = getUsersQuery(queryParams);
    const queryKey = ['user-data', queryParams];
    return useQuery({ queryKey, queryFn, enabled });
};

export const useMutateScheduleSupplyCell = () => {
    const queryClient = useQueryClient();
    const { showSnackbar } = useContext(NotificationContext);
    const { setSettings } = useScheduleSettings();

    return useMutation({
        mutationFn: expressMutation,
        onMutate: ({ id, body }) => {
            const previousDataMap = [];
            setSettings((prev) => ({ ...prev, editRow: id }));

            // iterate over all supply item queries
            queryClient
                .getQueryCache()
                .findAll(['list-view-supply-items'])
                .forEach((query) => {
                    const { queryKey } = query;
                    const oldData = queryClient.getQueryData(queryKey);

                    if (oldData) {
                        // cached data organized per day
                        const newData = oldData.map((row) => {
                            // update item in any day with the corresponding id
                            const updatedItems = row.items.map((item) =>
                                item?._id === id
                                    ? {
                                          ...item,
                                          ...body,
                                      }
                                    : item
                            );

                            return {
                                ...row,
                                items: updatedItems,
                            };
                        });

                        // update cache with modified data
                        queryClient.setQueryData(queryKey, newData);
                    }
                });

            // return previous data for potential rollback
            return { previousDataMap };
        },
        onSuccess: (response) => {
            const id = response[0];

            // invalidate only queries containing the updated row
            queryClient.invalidateQueries({
                predicate: (query) => {
                    if (query.queryKey[0] === 'list-view-supply-items') {
                        const cachedData = queryClient.getQueryData(query.queryKey);

                        return cachedData?.some((row) => row?.items.some((item) => item?._id === id));
                    }
                    return false;
                },
            });

            setSettings((prev) => ({ ...prev, editRow: undefined }));
            showSnackbar({ severity: 'success', message: 'Success' });
        },
        onError: () => {
            setSettings((prev) => ({ ...prev, editRow: undefined }));
            showSnackbar({ severity: 'error', message: 'Failed to update the schedule supply item. Please try again.' });
        },
    });
};
