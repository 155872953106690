import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { NotificationContext } from '../../../NotificationContext';
import configs from './configs';

const EditTextFieldCell = (props) => {
    const { id, field, value: cellValue, row, table, inputProps = {} } = props;
    const { valueAsNumber } = inputProps;
    const apiRef = useGridApiContext();
    const { showSnackbar } = useContext(NotificationContext);

    const mutateConfig = configs[table];
    const { mutateAsync } = mutateConfig.useHook();

    const handleMutate = async (value) => {
        try {
            const extractIdFields = mutateConfig.schema.meta().extractIdFields || [];
            const formattedRow = {
                ...row,
                [field]: valueAsNumber ? parseFloat(value) : value,
            };

            extractIdFields.forEach((_field) => {
                if (formattedRow[_field] && formattedRow[_field]._id) {
                    formattedRow[_field] = formattedRow[_field]._id;
                }
            });

            const body = mutateConfig.schema.validateSync(formattedRow, { stripUnknown: true });

            return mutateConfig.onMutate({ id, body }, { mutateAsync });
        } catch (error) {
            showSnackbar({ severity: 'error', message: error.message });
        }
    };

    const triggerMutate = () => {
        handleMutate(cellValue);
    };

    const handleChange = (event) => {
        const { value } = event.target;

        apiRef.current.setEditCellValue({
            id,
            field,
            value,
        });
    };

    return (
        <TextField
            variant="outlined"
            value={cellValue}
            onChange={handleChange}
            onBlur={triggerMutate}
            onKeyDown={({ key }) => {
                if (key === 'Enter') triggerMutate();
            }}
            type={valueAsNumber ? 'number' : 'text'}
            inputProps={inputProps}
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        border: 'none',
                    },
                },
            }}
        />
    );
};

export default EditTextFieldCell;
