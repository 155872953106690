import { useMutateScheduleSupplyCell } from './queries';
import MutateSupplyDialogSchema from '../../../main/apps/FunctionalApps/schedule-list-view/supply/MutateSupplyItem/MutateSupplyDialog.schema';

// configuration setup for handling mutations using a TanStack useMutation query
// each configuration defines:
// 1. onMutate: a function to handle the mutation logic
//    - it should accept two parameters:
//      - an object containing arguments: id (resource identifier) and body (payload)
//      - a function provided by the useMutation hook used to trigger a mutation, such as mutateAsync
//    - it must return the result of the call
// 2. useHook: a function that initializes and returns the specific hook used for this configuration
//    - this must be a function returning a hook invocation, ensuring hooks are called within React's lifecycle
// 3. schema: a Yup validation schema to validate and sanitize data before passing it to the mutation
//    - the schema should include fields relevant to this configuration's context and can include `meta` data
//      for additional processing (see example `extractIdFields` in MutateSupplyDialogSchema)

const schedule = {
    onMutate: ({ id, body }, { mutateAsync }) => {
        return mutateAsync({ method: 'PUT', endpoint: `/schedule-supply-item/${id}/false`, id, body });
    },
    useHook: () => useMutateScheduleSupplyCell(),
    schema: MutateSupplyDialogSchema,
};

export default { schedule };
