import { date, object, string, number, array } from 'yup';
import { transformEmptyStringToNull, transformNullToUndefined } from '../../../../../../custom-components/form-schemas/Schema.utils';

const MutateSupplyDialogSchema = object()
    .shape({
        route_id: string().required('Route is required'),
        producer_ids: array().of(string()).optional().transform(transformNullToUndefined),
        start_date: date()
            .required('Start date is required')
            .test('start_date before end_date', 'Start date must be before end date', function (value) {
                const { end_date } = this.parent;
                if (!value || !end_date) return true;
                return value < end_date;
            }),
        processor_id: string().optional().transform(transformNullToUndefined),
        end_date: date()
            .required('End date is required')
            .test('end_date after start_date', 'End date must be after start date', function (value) {
                const { start_date } = this.parent;
                if (!value || !start_date) return true;
                return value > start_date;
            }),
        hauling_id: string().optional().transform(transformNullToUndefined),
        recurring: object()
            .shape({
                frequency: number().optional(),
                unit: string().optional(),
                days: array().of(number()).optional(),
                recur_end_date: date().optional(),
                recur_day_of_month: number().optional(),
                number_occurrences: number().optional(),
                po_number_params: object()
                    .shape({
                        start_number: number().optional(),
                        padding_zeros: number().optional(),
                        prefix: string().optional(),
                        suffix: string().optional(),
                    })
                    .optional(),
            })
            .optional(),
        amount: number().required('Amount is required').min(1, 'Amount must be greater than 0'),
        notes: string().optional(),
        milk_category: string().optional(),
        po_number: string().optional().transform(transformEmptyStringToNull).nullable(),
        delivery_hauler_id: string().optional().transform(transformNullToUndefined),
    })
    .meta({
        // fields that we need to extract the _id from within data grid edit cells
        extractIdFields: ['route_id', 'processor_id', 'hauling_id'],
    });
export default MutateSupplyDialogSchema;
